<!-- =========================================================================================
  File Name: uploads/GlbUpload.vue
  Description: GLB Upload Form
========================================================================================== -->

<template>
  <vx-card>
    <vs-tabs alignment="fixed">
      <vs-tab id="create-new-object-tab" label="Create a new object" @click="clearHobject()">
        <object-creation
          ref="objectCreationRef"
          v-bind:model="model"
          v-bind:hobject="hobject"
          v-bind:anchor="anchor"
        ></object-creation>
      </vs-tab>
      <vs-tab label="Re-use an existing object">
        <form-select-existing-hobject
          v-bind:spaces="spaces"
          v-bind:nodes="nodes"
          v-bind:initialHobject="hobject"
        ></form-select-existing-hobject>
      </vs-tab>
    </vs-tabs>
    <div style="justify-content: space-between" class="vx-row w-full">
      <div class="vx-col"><vs-button @click="goBack()">Cancel</vs-button></div>
      <div class="vx-col">
        <vs-button @click="create()">Done</vs-button>
      </div>
    </div>
  </vx-card>
</template>
<script>
import * as HoverlayUtils from '@/assets/js/utils/hoverlay-utils.js'
import ObjectCreation from '@/views/hoverlay/components/ObjectCreation.vue'
import FormSelectExistingHobject from '@/views/hoverlay/pages/create/components/FormSelectExistingHobject.vue'

export default {
  components: {
    FormSelectExistingHobject,
    ObjectCreation,
  },
  props: {},
  data() {
    return {
      space: {},
      hobject: {},
      anchor: {},
      model: {
        provider: JSON.parse(localStorage.getItem('layerInfo')).name,
      },
      node: {
        angle_x: 0,
        angle_y: 0,
        angle_z: 0,
        quaternion_x: 0,
        quaternion_y: 0,
        quaternion_z: 0,
        quaternion_w: 0,
        x: 0,
        y: 0,
        z: 1.2,
        scale: 1,
      },
    }
  },
  computed: {
    nodes() {
      return this.$store.state.hoverlay.nodes
    },
    spaces() {
      return this.$store.state.hoverlay.spaces
    },
    anchors() {
      return this.$store.state.hoverlay.anchors
    },
    maxFileSize: function() {
      return HoverlayUtils.getMaxUploadFileSize()
    },
  },
  watch: {
    hobject: {
      handler: function(updatedValue) {},
      deep: true,
    },
  },
  async created() {
    if (this.$route.params.space_pid) {
      this.space = { pid: this.$route.params.space_pid }
    }
    if (this.$route.params.anchor_pid) {
      this.anchor = { pid: this.$route.params.anchor_pid }
    }
    this.$eventBus.$on('hobjectChanged', this.onHobjectChanged)
  },
  mounted() {},
  methods: {
    async create() {
      var success
      if (this.hobject.pid != null) success = true
      else success = await this.$refs.objectCreationRef.validate()

      if (!success) return

      this.$vs.loading({ text: ' ' }) // Add blank text so we can modified it later (progress pourcentage)

      // set default for node attached to reference image vs floor
      var anchor = this.anchors.find(anchor => anchor.pid == this.anchor.pid)
      if (anchor && anchor.anchor_type == 'image') {
        this.node = {
          angle_x: 0,
          angle_y: 0,
          angle_z: 0,
          quaternion_x: 0,
          quaternion_y: 0,
          quaternion_z: 0,
          quaternion_w: 0,
          x: 0,
          y: 0,
          z: 0,
          scale: 1,
        }
        var orientation = 'vertical'
        var height = 1
        try {
          orientation = JSON.parse(anchor.mark).position
          console.log(orientation)
        } catch (e) {
          e
        }
        try {
          height = JSON.parse(anchor.mark).height
          console.log(height)
        } catch (e) {
          e
        }
        if (orientation == 'vertical') {
          this.node.quaternion_x = 0.7071
          this.node.quaternion_w = 0.7071
          this.node.z = -height / 2
        } else {
          this.node.quaternion_x = 0
          this.node.quaternion_w = 0
          this.node.z = 0
        }
      }

      await HoverlayUtils.createNewNodeInSpace(
        this.$store,
        this.$vs,
        this.$router,
        this.hobject,
        this.space,
        this.node,
        this.anchor
      )

      this.$vs.loading.close()
    },

    onHobjectChanged(hobject) {
      this.hobject = hobject
    },
    clearHobject() {
      this.hobject = {}
    },
    goBack() {
      this.$router.go(-1)
    },
  },
}
</script>

<style lang="scss"></style>
